<template>
  <UiPopup
    v-model="modelValue"
    title="Filters"
    primary-button-text="Apply"
    secondary-button-text="Reset"
    size="extraBig"
    :secondary-button-closes="false"
    :popup-classes="authStore.getVisitorToken ? 'mt-24 ' : ''"
    @cancel="reset"
    @confirm="applyFilters"
  >
    <template #title>
      <h3>Filters</h3>
    </template>
    <template #default>
      <div class="flex flex-col gap-8 pt-4">
        <div v-if="!authStore.getVisitorToken" class="flex flex-row items-center gap-1">
          <span class="text-subhead-3">Display:</span>
          <UiInputTogglePrimary
            :model-value="Boolean(localFilters.only_exclusives)"
            name="onlyExclusives"
            @update:model-value="localFilters.only_exclusives = $event ? 1 : undefined"
          />
          <div class="flex flex-row items-center gap-1 text-black-70">
            <UiIcon name="diamond" />
            <span class="text-body">Exclusives only</span>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-12 overflow-hidden lg:grid-cols-2 xl:grid-cols-3">
          <div class="flex flex-col gap-8">
            <UiInputSelect
              v-model="localFilters.emirate_names"
              :items="filtersMap.emirateNames.items!"
              name="emirateNames"
              :loading="filtersMap.emirateNames.loading"
              multiple
              label="Emirate"
              placeholder="Emirate"
              persistent-placeholder
            />
            <UiInputSelect
              v-model="localFilters.locations"
              :items="filtersMap.locations.items!"
              name="districts"
              :loading="filtersMap.locations.loading"
              multiple
              label="City districts"
              placeholder="City districts"
              persistent-placeholder
            />
            <UiInputSelect
              v-model="localFilters.property_type"
              :items="filtersMap.propertyTypes.items!"
              :loading="filtersMap.propertyTypes.loading"
              name="propertyTypes"
              multiple
              label="Property types"
              placeholder="Property types"
              persistent-placeholder
            />
            <div class="flex flex-row items-end gap-4">
              <UiInputTextField
                id="area-from"
                v-model="localFilters.area_min"
                name="area-from"
                label="Property area"
                placeholder="From"
                suffix="m2"
                type="number"
                :error="useGetFieldErrors(v$, ['area_min'])"
              />
              <span class="mb-3">-</span>
              <UiInputTextField
                id="area-to"
                v-model="localFilters.area_max"
                name="area-to"
                placeholder="To"
                suffix="m2"
                type="number"
                :error="useGetFieldErrors(v$, ['area_max'])"
              />
            </div>
            <UiInputSelect
              v-model="localFilters.amenities"
              :items="filtersMap.amenities.items!"
              :loading="filtersMap.amenities.loading"
              multiple
              label="Amenities"
              name="Amenities"
              placeholder="Amenities"
              persistent-placeholder
            />
          </div>
          <div class="flex flex-col gap-8">
            <UiInputOptions
              id="stage"
              v-model="localFilters.building_status"
              name="stage"
              :items="filtersMap.buildingStatuses.items!"
              :loading="filtersMap.buildingStatuses.loading"
              label="Construction stages"
            />
            <UiInputOptions
              id="bedrooms"
              v-model="localFilters.bedrooms"
              name="bedrooms"
              :items="filtersMap.bedrooms.items!"
              :loading="filtersMap.bedrooms.loading"
              label="Bedrooms"
              multiple
            />
            <div class="flex flex-row items-center gap-4">
              <UiInputTextField
                id="budget"
                v-model="localFilters.price_max"
                name="budget"
                label="Budget"
                placeholder="Number"
                type="number"
                prefix="AED"
                :error="useGetFieldErrors(v$, ['price_max'])"
              />
              <UiInputTextField
                id="down-payment"
                v-model="localFilters.down_payment"
                name="down-payment"
                label="Down payment"
                placeholder="Number"
                type="number"
                prefix="AED"
                :error="useGetFieldErrors(v$, ['down_payment'])"
              />
            </div>
            <div>
              <UiInputSelect
                v-model="localFilters.sale_status"
                :items="filtersMap.salesStatuses.items!"
                :loading="filtersMap.salesStatuses.loading"
                multiple
                label="Sales status"
                name="sales-status"
                placeholder="Any"
                persistent-placeholder
              >
                <template #default>
                  <div
                    v-if="!(localFilters.sale_status.length === 1 && localFilters.sale_status[0] === soldOutStatusId)"
                    class="text-caption flex h-6 cursor-pointer flex-row items-center gap-[2px] rounded-lg bg-primary-100 px-[6px] py-[3px] text-white"
                    @click.stop="clearSalesStatuses"
                  >
                    {{ includeSoldOut ? localFilters.sale_status.length - 1 : localFilters.sale_status.length }}
                    <UiIcon name="small-close" size="xxs"></UiIcon>
                  </div>
                </template>
              </UiInputSelect>
              <div class="mt-2 flex">
                <UiInputTogglePrimary
                  :model-value="includeSoldOut"
                  name="includeSoldOut"
                  class="!ml-0"
                  @update:model-value="toggleSoldOut($event)"
                />
                <div class="flex flex-row items-center gap-1 text-black-70">
                  <span class="text-body">Show sold out</span>
                </div>
              </div>
            </div>
            <UiInputDatePicker
              v-model="localFilters.handover_date"
              name="dateRange"
              placeholder="Handover date"
              label="Handover date"
            />
          </div>
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row items-center gap-2">
                <UiInputTextField
                  id="floor-from"
                  name="floor-from"
                  placeholder="Not lower ex.3"
                  label="Floor"
                  disabled
                  model-value=""
                />
                <UiInputTextField
                  id="floor-to"
                  name="floor-to"
                  placeholder="Not higher ex.7"
                  label="Floor"
                  disabled
                  model-value=""
                />
              </div>
              <UiInputCheckbox
                v-model="localFilters.floor_plan"
                name="floorPlanAvailable"
                label="Floor plan available"
              />
            </div>
            <UiInputCheckbox
              v-model="localFilters.post_handover_payment_plan"
              name="postHandoverPaymentPlan"
              label="Post-handover payment plan"
            />
            <UiInputCheckbox v-model="localFilters.roi_percent" name="guaranteedROI" label="Guaranteed ROI" />
          </div>
        </div>
      </div>
    </template>
  </UiPopup>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { useVuelidate } from '@vuelidate/core'
import { maxValue, helpers } from '@vuelidate/validators'
import type { DynamicFilter, PropertyTypes, LibraryItem } from '@/types'
import { DICTIONARIES, PROPERTIES_SALE_STATUSES } from '@/constants'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue', 'cancel', 'input', 'reset'])

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  filters: PropertyTypes.Filters
}

const props = defineProps<Props>()

const localFilters = ref<PropertyTypes.Filters>({ ...cloneDeep(props.filters) })
const salesStatuses = ref<LibraryItem[]>([])

const rules = computed(() => ({
  area_min: { maxValue: helpers.withMessage('Property min area is too high', maxValue(999999999)) },
  area_max: { maxValue: helpers.withMessage('Property max area is too high', maxValue(999999999)) },
  down_payment: { maxValue: helpers.withMessage('Down payment is too high', maxValue(999999999)) },
  price_max: { maxValue: helpers.withMessage('Budget is too high', maxValue(999999999)) },
}))

const v$ = useVuelidate(rules, localFilters)

const soldOutStatusId = computed(() => {
  return salesStatuses.value.find((status) => status.code === PROPERTIES_SALE_STATUSES.SOLD_OUT)?.id
})

const filtersMap: Record<string, DynamicFilter> = reactive({
  locations: {
    name: 'locations',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_LOCATIONS)),
  },
  emirateNames: {
    name: 'emirate_names',
    items: [],
    init: async () => {
      const { data } = await usePCDictionary(DICTIONARIES.EMIRATE_NAMES)

      return useSerializeLibraryItems(data.map(({ id }) => ({ id, name: String(id), code: String(id) })))
    },
  },
  propertyTypes: {
    name: 'property_type',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_TYPES)),
  },
  amenities: {
    name: 'amenities',
    items: [],
    init: async () => useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_AMENITIES)),
  },
  buildingStatuses: {
    name: 'building_status',
    items: [
      {
        value: null,
        text: 'Any',
      },
    ],
    init: async () =>
      useSerializeLibraryItems(await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_BUILDING_STATUSES)),
  },
  bedrooms: {
    name: 'bedrooms',
    items: [
      {
        value: null,
        text: 'Any',
      },
      {
        value: 0,
        text: 'Studio',
      },
      {
        value: 1,
        text: '1',
      },
      {
        value: 2,
        text: '2',
      },
      {
        value: 3,
        text: '3',
      },
      {
        value: 4,
        text: '4+',
      },
    ],
  },
  salesStatuses: {
    name: 'sale_status',
    items: [],
    init: async () =>
      useSerializeLibraryItems(
        (await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)).filter(
          (status) => status.code !== PROPERTIES_SALE_STATUSES.SOLD_OUT
        )
      ),
  },
})

const includeSoldOut = computed(() => localFilters.value.sale_status?.includes(soldOutStatusId.value as number))

const getFiltersValues = async () => {
  await Promise.all(
    Object.entries(filtersMap).map(async ([_, filter]) => {
      if (filter.init) {
        filter.loading = true
        filter.items = filter.items?.concat(await filter.init())
        filter.loading = false
      }
    })
  )
}

onNuxtReady(async () => {
  await getFiltersValues()
  salesStatuses.value = await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)
})

const reset = () => {
  localFilters.value = {
    query: '',
    property_type_ids: [],
    sale_status: [],
    bedrooms: [],
    budget: [],
    order_by: '-updated_at',
    order_way: 'asc',
    handover_date: '',
  }
  emits('reset')
}

const applyFilters = async () => {
  if (!(await v$.value.$validate())) return
  emits('input', localFilters.value)
  modelValue.value = false
}

const toggleSoldOut = (value: boolean) => {
  if (value) {
    localFilters.value.sale_status?.push(soldOutStatusId.value as number)
  } else {
    localFilters.value.sale_status = localFilters.value.sale_status?.filter(
      (status) => status !== soldOutStatusId.value
    )
  }
}

const clearSalesStatuses = () => {
  localFilters.value.sale_status = includeSoldOut.value
    ? localFilters.value.sale_status.filter((status) => status === soldOutStatusId.value)
    : []
}
</script>

<style scoped></style>
